<template>
  <div>
    <Divider dashed style="margin-bottom: 5px;"><span class="divider-text">在刊统计</span></Divider>
    <p class="remark">备注：仅汇总常规资源，包括：广告灯箱(6封、12封、18封、梯楣、超大)、广告看板(梯旁、轨旁、18封)</p>

    <h3 class="m-t-5">商业在刊统计</h3>
    <Table stripe size="small" :data="statisticData" :columns="bussinessColumn"></Table>

    <h3 class="m-t-5">公益在刊统计</h3>
    <Table stripe size="small" :data="statisticData" :columns="gyColumn"></Table>

    <h3 class="m-t-5">填充在刊统计</h3>
    <Table stripe size="small" :data="statisticData" :columns="fillColumn"></Table>
  </div>
</template>

<script>
import { getAssetPublishStatistic } from '@/api/msp/publishstatistical'

export default {
  data () {
    return {
      statisticData: [],

      bussinessColumn: [
        { title: '线路', key: 'assetName' },
        {
          title: '商业在刊数',
          align: 'right',
          width: 90,
          key: 'businessCount'
        },
        {
          title: '刊挂率',
          align: 'right',
          width: 80,
          render: (h, params) => {
            return h('span', this.computedRate(params.row.businessCount, params.row.totalCount))
          }
        }
      ],

      gyColumn: [
        { title: '线路', key: 'assetName' },
        {
          title: '公益在刊数',
          align: 'right',
          width: 90,
          key: 'gyCount'
        },
        {
          title: '刊挂率',
          align: 'right',
          width: 80,
          render: (h, params) => {
            return h('span', this.computedRate(params.row.gyCount, params.row.totalCount))
          }
        }
      ],

      fillColumn: [
        { title: '线路', key: 'assetName' },
        {
          title: '填充在刊数',
          align: 'right',
          width: 90,
          key: 'fillCount'
        },
        {
          title: '刊挂率',
          align: 'right',
          width: 80,
          render: (h, params) => {
            return h('span', this.computedRate(params.row.fillCount, params.row.totalCount))
          }
        }
      ]
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const queryModel = {
        actionDate: this.publishDate
      }

      getAssetPublishStatistic(queryModel).then(res => {
        const totalColumn = {
          assetName: '合计',
          businessCount: 0,
          fillCount: 0,
          gyCount: 0,
          totalCount: 0,
          xwCount: 0
        }

        this.statisticData = res
        res.forEach(element => {
          totalColumn.businessCount += element.businessCount
          totalColumn.fillCount += element.fillCount
          totalColumn.gyCount += element.gyCount
          totalColumn.totalCount += element.totalCount
          totalColumn.xwCount += element.xwCount
        })
        this.statisticData.push(totalColumn)
      })
    },
    computedRate (count, totalCount) {
      const rate = (count * 100) / totalCount

      return rate.toFixed(2) + '%'
    }
  },
  computed: {
    publishDate () {
      return this.$store.state.statistic.publishDate
    }
  },
  watch: {
    publishDate () {
      this.initPageData()
    }
  }
}
</script>

<style>

</style>
